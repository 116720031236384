<template>
    <div class="pagePadding" ref="pagePadding">
        <div ref="formRef" class="fromFlex">
            <Form inline class="formMarginBtm20">
                <!-- 关键字 -->
                <Row>
                    <FormItem>
                        <span>关键字：</span>
                        <Input class="iviewIptWidth200" :value="from.keyword" @on-blur="onBlur" maxlength="20"></Input>
                    </FormItem>
                    <!-- 关键字 -->
                    <FormItem>
                        <span>产品名称：</span>
                        <Select class="iviewIptWidth200" v-model="from.product_name" clearable filterable>
                            <Option v-for="item in product_nameList" :value="item.name" :key="item.name">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    <!-- 关键字 -->
                    <FormItem>
                        <span>注册证号：</span>
                        <Select class="iviewIptWidth200" v-model="from.licence_code" clearable filterable>
                            <Option v-for="item in licence_codeList" :value="item.licence_code" :key="item.product_licence_id">{{ item.licence_code }}</Option>
                        </Select>
                        <!-- <Input class="iviewIptWidth200" v-model="from.licence_code" type="tel" maxlength="20"></Input> -->
                    </FormItem>
                    <!-- 关键字 -->
                    <FormItem>
                        <span>规格型号：</span>
                        <Select class="iviewIptWidth200" v-model="from.model_name" clearable filterable>
                            <Option v-for="item in model_nameList" :value="item.model_name" :key="item.code_id">{{ item.model_name }}</Option>
                        </Select>
                        <!-- <Input class="iviewIptWidth200" v-model="from.model_name" type="tel" maxlength="20"></Input> -->
                    </FormItem>
                    <!-- 关键字 -->
                    <FormItem>
                        <span>生产厂家：</span>
                        <Select class="iviewIptWidth200" v-model="from.factory_name" clearable filterable>
                            <Option v-for="item in factory_nameList" :value="item.factory_name" :key="item.factory_id">{{ item.factory_name }}</Option>
                        </Select>
                        <!-- <Input class="iviewIptWidth250" v-model="from.factory_name" type="tel" maxlength="20"></Input> -->
                    </FormItem>
                    <!-- <Radio v-model="from.is_matching">已匹配</Radio> -->
                    <FormItem :label-width="10">
                        <!-- <Radio v-model="flg" true-value="1" false-value="0">已匹配</Radio> -->
                        <!-- <Checkbox v-model="flg">已匹配</Checkbox> -->

                    </FormItem>
                    <div class="swith">
                        <span style="margin-right: 10px">已匹配</span>
                        <i-switch v-model="flg">
                            <span slot="open">是</span>
                            <span slot="close">否</span>
                        </i-switch>
                    </div>
                    <br />
                    <FormItem class="po-create-number marginRight0" :label-width="10">
                        <span class="pageBtn finger btnAdd marginLeft30" @click="printing">
                            <Spin style="display: inline-block;" v-show="Loading">
                                <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
                            </Spin>
                            导出
                        </span>
                    </FormItem>
                    <!-- 按钮 -->
                    <FormItem class="po-create-number marginRight0" :label-width="10">
                        <span class="pageBtn finger btnSure marginLeft30" @click="searchItem">查询</span>
                    </FormItem>
                    <!-- 单选框 -->
                </Row>
            </Form>
        </div>

        <div class="tabelDiv">
            <!--      <Table height="600" row-key="id" :columns="columnsList" :data="dataList" v-if="dataList.length !== 0"></Table>-->
            <vxe-table border :stripe="false" resizable :tree-config="{ children: 'children' }" class="tab" style="text-align:left;" :data="dataList" :height="tabHeight">
                <vxe-table-column title="类别" tree-node width="150">
                    <template v-slot="{ row }" id="menuTitle">
                        <span class="caidan">{{ row.type }}</span>
                    </template>
                </vxe-table-column>
                <vxe-table-column field="product_model_code" title="物料号" align="left" minWidth="150"></vxe-table-column>
                <vxe-table-column field="product_code_child" title="子物料号" align="left" minWidth="300"></vxe-table-column>
                <vxe-table-column field="product_model_code_desc" title="物料描述" align="left" minWidth="300"></vxe-table-column>
                <vxe-table-column field="name" title="产品名称" align="left" minWidth="200"></vxe-table-column>
                <vxe-table-column field="licence_code" title="注册证号" align="left" minWidth="200"></vxe-table-column>
                <vxe-table-column field="model_name" title="规格型号" align="left" minWidth="200"></vxe-table-column>
                <vxe-table-column field="factory_name" title="生产厂家" align="left" minWidth="300"></vxe-table-column>
                <vxe-table-column field="Range" title="库存上限/下限" align="left" minWidth="200"></vxe-table-column>
                <vxe-table-column title="操作" width="100" align="left" fixed="right">
                    <template v-slot="{ row, rowIndex }">
                        <div class="doClass" v-if="row.Range" @click="systemLine(row, rowIndex)">
                            设置上下限
                        </div>
                    </template>
                </vxe-table-column>
                <template v-slot:empty v-if="dataList !== null && dataList.length == 0">
                    <span style="color: red;">
                        <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
                    </span>
                </template>
            </vxe-table>
        </div>
        <!-- <expandRow ></expandRow> -->
        <!-- 修改物料号 -->
        <Modal v-model="editModal" title="修改上下限">
            <Form inline class="formMarginBtm20">
                <FormItem>
                    <span>库存上限:</span>
                    <InputNumber class="iviewIptWidth250 marginLeft30" v-model="topLimit" :max="999999999" :min="0" placeholder="请输入"></InputNumber><br />
                </FormItem>
                <FormItem class="po-create-number marginRight0">
                    <span>库存下限:</span>
                    <InputNumber class="iviewIptWidth250 marginLeft30" v-model="bottomLimit" :max="999999999" :min="0" placeholder="请输入"></InputNumber>
                </FormItem>
            </Form>
            <div slot="footer">
                <span class="pageBtn finger btnSure marginRight20px" @click="editLimit">确定</span>
                <span class="pageBtn finger btnCancle" @click="editModal = false">取消</span>
            </div>
        </Modal>
    </div>
</template>

<script>
// import expandRow from '@/components/table-expand'
export default {
    components: {
        // expandRow
    },
    data() {
        return {
            tabHeight: 100,
            topLimit: 0,
            bottomLimit: 0,
            wuliaoNumber: '',
            columnsList: [
                {
                    title: '类别',
                    align: 'center',
                    key: 'type',
                    width: 200,
                    tree: true,
                },
                {
                    title: '物料号',
                    align: 'left',
                    key: 'product_model_code',
                    width: 200,
                },
                {
                    title: '子编号',
                    key: 'product_code_child',
                    align: 'left',
                    width: 150,
                },
                {
                    title: '物料描述',
                    key: 'product_model_code_desc',
                    align: 'left',
                    width: 600,
                },
                {
                    title: '产品名称',
                    key: 'name',
                    align: 'left',
                    width: 300,
                },
                {
                    title: '注册证号',
                    key: 'licence_code',
                    align: 'left',
                    width: 260,
                },
                {
                    title: '规格型号',
                    key: 'model_name',
                    align: 'left',
                    width: 160,
                },
                {
                    title: '生产厂家',
                    key: 'factory_name',
                    align: 'left',
                    width: 260,
                },
                {
                    title: '库存上限/下限',
                    key: 'Range',
                    align: 'center',
                    width: 200,
                },
                // {
                //   title: '操作',
                //   align: 'center',
                //   width: 200,
                //   fixed: 'right',
                //   render: (h, params) => {
                //     if(params.row.Range ) {
                //     return h(
                //       'span',
                //       {
                //         style: {
                //           color: '#389AFC',
                //           fontSize: '14px',
                //           cursor: 'pointer',
                //           },
                //         on: {
                //           click: () => {
                //             this.editModal = true
                //             this.wuliaoNumber = params.row.product_model_code
                //             this.bottomLimit = params.row.inventory_floor
                //             this.topLimit = params.row.inventory_ceiling
                //             console.log(params.row, params.row)
                //           },
                //         },
                //       },
                //       '设置上下限'
                //     )
                //     }
                //   },
                //   align: 'center',
                //   minWidth: 75,
                // },
            ],
            // 产品名称下拉
            product_nameList: [],
            // 注册证号
            licence_codeList: [],
            // 规格型号
            model_nameList: [],
            // 生产厂家
            factory_nameList: [],
            dataList: [],
            pageWord: 'hxt',
            from: {
                keyword: '',
                product_name: '',
                licence_code: '',
                model_name: '',
                factory_name: '',
                is_matching: '',
            },
            flg: false,
            editModal: false,
            Loading: false,
            rowIndex: '',
        }
    },
    methods: {
        onBlur(e) {
            this.from.keyword = e.target.value ? e.target.value : ''
        },
        systemLine(row, index) {
            this.rowIndex = index
            this.wuliaoNumber = row.product_model_code
            this.bottomLimit = row.inventory_floor
            this.topLimit = row.inventory_ceiling
            this.editModal = true
        },
        // 打印问价
        printing() {
            if (this.flg) {
                this.from.is_matching = '1'
            } else {
                this.from.is_matching = ''
            }
            this.Loading = true
            this.$http.downFile(this.$api.getPrinting, this.from).then(res => {
                this.Loading = false
                let blob = new Blob([res.data], { type: 'application/x-xlsx' })
                const aLink = document.createElement('a')
                aLink.href = URL.createObjectURL(blob)
                aLink.download = '产品字典.xlsx'
                aLink.click()
            })
        },
        // 修改物料号上下限
        editLimit() {
            let product_model_code = this.wuliaoNumber
            let inventory_ceiling = this.topLimit
            let inventory_floor = this.bottomLimit
            let reg = /^\d+$/ // 验证规则-非负整数
            if (inventory_ceiling || inventory_floor) {
                if (!inventory_floor) {
                    this.$Message.error('请填写库存下限')
                }
                if (!inventory_ceiling) {
                    this.$Message.error('请填写库存上限')
                }
                if (inventory_ceiling && inventory_floor) {
                    if (reg.test(inventory_ceiling) && reg.test(inventory_floor)) {
                        if (Number(inventory_ceiling) > Number(inventory_floor)) {
                            let obj = { product_model_code, inventory_ceiling, inventory_floor }
                            this.$http.put(this.$api.setLimit, obj).then(res => {
                                if (res.status) {
                                    this.editModal = false
                                    this.$root.eventHub.$emit('updatatable')
                                }
                            })
                        } else {
                            this.$Message.error('库存上限必须大于库存下限')
                        }
                    } else {
                        this.$Message.error('请填写非负整数')
                    }
                }
            }
            if (!inventory_ceiling && !inventory_floor) {
                let obj = { product_model_code }
                this.$http.put(this.$api.setLimit, obj).then(res => {
                    if (res.status) {
                        this.editModal = false
                        this.$root.eventHub.$emit('updatatable')
                    }
                })
            }
        },
        async getTableList() {
            this.dataList = []
            if (this.flg) {
                this.from.is_matching = '1'
            } else {
                this.from.is_matching = ''
            }
            let keyword = this.from.keyword || this.pageWord

            let data = JSON.parse(JSON.stringify(this.from))

            // let keyword = this.from.keyword || ''
            // if (keyword === 'hxt') {
            //   let home_click = '1'
            //   let info =  {
            //     keyword,
            //     home_click,
            //     product_name: this.from.product_name,
            //     licence_code: this.from.licence_code,
            //     model_name: this.from.model_name,
            //     factory_name: this.from.factory_name,
            //   }
            //    this.$http.get(this.$api.getDataDictionary, info , true).then(res => {
            //     if (res.status) {
            //       res.data = res.data.sort()
            //       for (const item of res.data) {
            //         this.$set(item, '_expanded', true)
            //       }
            //     let obj = []
            //     res.data.forEach((item,index) =>{
            //        obj = {
            //         id: '10'+index,
            //         type: res.data[index].product_group,
            //         product_model_code: '',
            //         product_code_child: '',
            //         product_model_code_desc: '',
            //         name:'',
            //         licence_code: '',
            //         model_name: '',
            //         factory_name: ''
            //       }
            //      let arr =  Object.keys(res.data[index].product_group_data)
            //      if(arr.length != 0) {
            //        obj.children = []
            //         arr.forEach((element,eindex) => {
            //           obj.children.push({
            //             id: '10'+index+'0'+eindex,
            //             type: element,
            //             product_model_code: '',
            //             product_code_child: '',
            //             product_model_code_desc: '',
            //             name:'',
            //             licence_code: '',
            //             model_name: '',
            //             factory_name: ''
            //           })
            //         let arr2 = res.data[index].product_group_data[element]
            //         if(arr2 != 0) {
            //             obj.children[eindex].children = []
            //             arr2.forEach((iview,iindex) => {

            //               obj.children[eindex].children.push({
            //                 id: '10'+index+'0'+eindex+'0'+iindex,
            //                 product_model_code: iview.product_model_code,
            //                 product_code_child: iview.product_code_child,
            //                 product_model_code_desc: iview.product_model_code_desc,
            //                 name: iview.name,
            //                 licence_code: iview.licence_code,
            //                 model_name: iview.model_name,
            //                 factory_name: iview.factory_name,
            //                 inventory_floor: iview.inventory_floor,
            //                 inventory_ceiling: iview.inventory_ceiling,
            //                 Range:  (iview.inventory_floor?iview.inventory_floor: '-') + ' / ' + (iview.inventory_ceiling ? iview.inventory_ceiling : '-')
            //               })
            //             })
            //         }
            //         })

            //      }
            //       this.dataList.push(obj)
            //     })
            //     }
            //   })
            // } else {
            let res = await this.$http.get(this.$api.getDataDictionary, data, true)
            if (res.status) {
                res.data = res.data.sort()
                for (const item of res.data) {
                    this.$set(item, '_expanded', true)
                }
                this.getTree(res.data)
            }
            // }
        },
        // 获取下拉框的信息
        getSelectData() {
            // 获取产品名称下拉
            this.$http.get(this.$api.getProductNameSelect, '', true).then(res => {
                this.product_nameList = res.data
            })
            // 获取生产厂家下拉
            this.$http.get(this.$api.getFactorySelect, '', true).then(res => {
                this.factory_nameList = res.data
            })
            // setTimeout(() => {
            // 获取规格下拉
            this.$http.get(this.$api.getSpecificationsSelect, { status: true, message: '' }, true).then(res => {
                let arr = res.data
                arr.forEach((item, index) => {
                    if (item.model_name) {
                        this.model_nameList.push(item)
                    }
                })
            })
            // }, 3000)
            // 获取注册证下拉
            this.$http.get(this.$api.getRegisterSelect, { status: true, message: '' }, true).then(res => {
                this.licence_codeList = res.data
            })
        },
        searchItem() {
            // let strLength = this.from.keyWord.length
            if (!this.from.keyword && !this.from.product_name && !this.from.licence_code && !this.from.model_name && !this.from.factory_name && !this.flg) {
                this.$Message.error('至少选择一项信息！')
            } else {
                this.getTableList()
            }
        },
        // 表格数据改成树状结构
        getTree(Data) {
            // this.dataList = res.data
            let obj = []
            Data.forEach((item, index) => {
                obj = {
                    id: '10' + index,
                    type: Data[index].product_group,
                    product_model_code: '',
                    product_code_child: '',
                    product_model_code_desc: '',
                    name: '',
                    licence_code: '',
                    model_name: '',
                    factory_name: '',
                    _showChildren: true,
                }
                let arr = Object.keys(Data[index].product_group_data)
                if (arr.length != 0) {
                    obj.children = []
                    arr.forEach((element, eindex) => {
                        obj.children.push({
                            id: '10' + index + '0' + eindex,
                            type: element,
                            product_model_code: '',
                            product_code_child: '',
                            product_model_code_desc: '',
                            name: '',
                            licence_code: '',
                            model_name: '',
                            factory_name: '',
                            _showChildren: true,
                        })
                        let arr2 = Data[index].product_group_data[element]
                        if (arr2 != 0) {
                            obj.children[eindex].children = []
                            arr2.forEach((iview, iindex) => {
                                obj.children[eindex].children.push({
                                    id: '10' + index + '0' + eindex + '0' + iindex,
                                    product_model_code: iview.product_model_code,
                                    product_code_child: iview.product_code_child,
                                    product_model_code_desc: iview.product_model_code_desc,
                                    name: iview.name,
                                    licence_code: iview.licence_code,
                                    model_name: iview.model_name,
                                    factory_name: iview.factory_name,
                                    inventory_floor: iview.inventory_floor,
                                    inventory_ceiling: iview.inventory_ceiling,
                                    Range: (iview.inventory_floor ? iview.inventory_floor : '-') + ' / ' + (iview.inventory_ceiling ? iview.inventory_ceiling : '-'),
                                    _showChildren: true,
                                })
                            })
                        }
                    })
                }
                this.dataList.push(obj)
                console.log(this.dataList, 'this.dataList')
            })
        },
    },
    created() {
        let flag = this.$route.query.select
        if (flag) {
            let home_click = '1'
            let keyword = this.from.keyword || this.pageWord
            // this.pageWord = 'hxt'
            this.$http.get(this.$api.getDataDictionary, { keyword, home_click }, true).then(res => {
                if (res.status) {
                    res.data = res.data.sort()
                    this.getTree(res.data)
                    for (const item of this.dataList) {
                        this.$set(item, '_expanded', true)
                    }
                }
            })
        }
        //  else {
        //   let keyword = this.from.keyword || this.pageWord
        //   // this.pageWord = 'hxt'
        //   this.$http.get(this.$api.getDataDictionary, { keyword, home_click: '1'  }, true).then(res => {
        //     if (res.status) {
        //       res.data = res.data.sort()
        //       this.getTree(res.data)
        //       for (const item of this.dataList) {
        //         this.$set(item, '_expanded', true)
        //       }
        //     }
        //   })
        // }
        this.$root.eventHub.$off('updatatable')
        this.$root.eventHub.$on('updatatable', () => {
            this.getTableList()
        })
        // 获取下拉数据
        this.getSelectData()
    },
    mounted() {
        this.tabHeight = this.$refs.pagePadding.offsetHeight - this.$refs.formRef.offsetHeight - 50
    },
}
</script>

<style lang="less" scoped>
.doClass {
  color: #389afc;
  fontsize: 14px;
  cursor: pointer;
}
.marginLeft30 {
  margin-left: 30px;
}
/deep/ .ivu-table-expanded-cell {
  padding: 0 0 !important;
  height: 100%;
}
.iviewIptWidth200 {
  width: 200px;
}
.marginRight20px {
  margin-right: 20px;
}
.pagePadding /deep/ .vxe-cell .vxe-cell--title {
  text-align: left;
}
.fromFlex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.swith {
  display: inline-block;
  height: 40px;
  // display: flex;
  align-items: center;
  line-height: 40px;
  font-size: 16px;
}
/deep/ .ivu-switch {
  width: 50px;
  height: 25px;
  line-height: 25px;
}
/deep/ .ivu-switch:after {
  width: 24px;
  height: 24px;
  top: 0px;
}
/deep/ .ivu-switch-checked .ivu-switch-inner {
  left: 7px;
}
/deep/ .ivu-switch-inner {
  left: 28px;
  font-size: 15px;
}
/deep/ .ivu-switch-checked:after {
  left: 25px;
}
</style>
